import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FLCIcon from "../../images/project_card_icons/fun_learning_company_website/fun_learning_company_website.jpg"
import Bellcurve from "../../images/project_card_icons/bellcurve_video_game/bellcurve_video_game.jpg"
import TGRIcon from "../../images/project_card_icons/three_gods_riddle_application/three_gods_riddle.jpg"
import { FaGithub } from "react-icons/fa"

const Code = () => (
  <Layout>
    <SEO title="Home" />
    <p className="temp-note">More projects are on the way!</p>
    <h1 className="page-title dark-theme-text">Coding Projects</h1>
    <section className="projects">
      <div className="projects__card">
        <div className="projects__card__title">
          <h3>Fun Learning Company Website</h3>
        </div>
        <div className="projects__card__description">
          <p className="bring-forward">
            A feature-packed website developed with LAMP stack, complete with payment integration, unique UI/UX, and user authentication. <br />
          </p>            
          <img src={ FLCIcon } />
          <div className="gradient-overlay"></div>
        </div> 
        <div className="projects__card__links">
          <Link to="./project_fun_learning_company_website" className="btn--primary">Project highlights</Link>
          {/* <a href="/project_fun_learning_company_website" className="btn--primary">Project highlights</a> */}
          <a href="https://funlearningcompany.com/" target="_blank" rel="noopener noreferrer" className="btn--secondary">Visit site</a>
          {/* <a href="https://github.com/junosprite007/funlearningcompany/" className="social-media"><FaGithub /></a> */}
        </div>
      </div>
      <div className="projects__card">
        <div className="projects__card__title">
          <h3>Bellcurve Video Game</h3>
        </div>
        <div className="projects__card__description">
          <p className="bring-forward">A simple, but original video game, written in TypeScript, where you create a bridge of probability with every pair of blocks you choose.</p>            
          <img src={ Bellcurve } />
          <div className="gradient-overlay"></div>
        </div> 
        <div className="projects__card__links">
          <Link to="./bellcurve_video_game" className="btn--primary">Project highlights</Link>
          {/* <a href="/project_fun_learning_company_website" className="btn--primary">Project highlights</a> */}
          <a href="https://arcade.makecode.com/94048-60915-83783-32937" target="_blank" rel="noopener noreferrer" className="btn--secondary">Play!</a>
          <a href="https://github.com/Junosprite007/Bellcurve" target="_blank" rel="noopener noreferrer" className="social-media"><FaGithub /></a>
        </div>
      </div>

      {/* <div className="projects__card">
        <div className="projects__card__title">
          <h3>Three Gods Riddle Application</h3>
        </div>
        <div className="projects__card__description">
          <p className="bring-forward">
            A recreation of a complex logic puzzle called the Three Gods Riddle, written in Python. It even almost works.
          </p>            
          <img src={ TGRIcon } />
          <div className="gradient-overlay"></div>
        </div> 
        <div className="projects__card__links">
          <a href="/project_fun_learning_company_website" className="btn--primary">Project highlights</a>
          <a href="https://funlearningcompany.com/" target="_blank" rel="noopener noreferrer" className="btn--secondary">Visit site</a>
          <a href="https://github.com/junosprite007/funlearningcompany/" className="social-media"><FaGithub /></a>
        </div>
      </div> */}

    </section>
  </Layout>
)

export default Code
